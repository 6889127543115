import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "merida" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "upptäck-vår-katalog-av-merida-elcyklar"
    }}>{`Upptäck Vår Katalog av Merida Elcyklar`}</h1>
    <p>{`Välkommen till vår Merida elcykel-sida, där du kan utforska och hitta information om alla våra elcyklar från det ledande varumärket Merida. Oavsett om du är en erfaren cyklist eller nybörjare, erbjuder Merida ett brett sortiment av elcyklar som passar alla behov. Från avancerade mountainbikes till eleganta hybridcyklar för stadskörning - här finns något för alla.`}</p>
    <h3 {...{
      "id": "merida-eone-sixty-serien"
    }}>{`Merida eONE-SIXTY Serien`}</h3>
    <p>{`Merida eONE-SIXTY är en premiumserie av heldämpade elektriska mountainbikes, särskilt utformade för tuff terräng och enduro-körning. `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Motor och Batteri:`}</strong>{` Shimano EP801 motor och integrerat batteri på upp till 750 Wh.`}</li>
      <li parentName="ul"><strong parentName="li">{`Räckvidd:`}</strong>{` Upp till 100 km, perfekt för långa äventyr på stigar och berg.`}</li>
      <li parentName="ul"><strong parentName="li">{`Design och Komfort:`}</strong>{` Lätt kolfiberram och högkvalitativa komponenter som Shimano XT-växlar och robust fjädring för optimal kontroll och komfort.`}</li>
      <li parentName="ul"><strong parentName="li">{`Användning:`}</strong>{` Idealisk för de som söker kraft och hållbarhet i krävande terräng.`}</li>
    </ul>
    <h3 {...{
      "id": "merida-ebigtour-serien"
    }}>{`Merida eBIG.TOUR Serien`}</h3>
    <p>{`Merida eBIG.TOUR hybrid elcyklar är den perfekta blandningen av terräng- och stadscykel. `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Motor och Batteri:`}</strong>{` Shimano EP600 och EP8 motorer med batterikapacitet upp till 750 Wh.`}</li>
      <li parentName="ul"><strong parentName="li">{`Räckvidd:`}</strong>{` Upp till 100 km, vilket gör dessa cyklar perfekta för både pendling och äventyr.`}</li>
      <li parentName="ul"><strong parentName="li">{`Design och Komfort:`}</strong>{` MTB-inspirerad geometri, stora 29-tums däck och dämpad framgaffel för komfort på alla underlag.`}</li>
      <li parentName="ul"><strong parentName="li">{`Användning:`}</strong>{` Idealisk för både stadsmiljö och äventyr i naturen.`}</li>
    </ul>
    <h3 {...{
      "id": "merida-espresso-serien"
    }}>{`Merida Espresso Serien`}</h3>
    <p>{`Merida Espresso är den mångsidiga hybridserien som kombinerar elegans och funktionalitet för stadsmiljöer och längre turer.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Motor och Batteri:`}</strong>{` Kraftfull Shimano EP600, EP6 och EP801 motorer samt batterier från 504 Wh till 630 Wh.`}</li>
      <li parentName="ul"><strong parentName="li">{`Räckvidd:`}</strong>{` Upp till 100 km, perfekt för daglig pendling och äventyrsresor.`}</li>
      <li parentName="ul"><strong parentName="li">{`Design och Komfort:`}</strong>{` Låg instegsdesign och dämpad framgaffel för bekväm åktur, robusta 28-tums hjul och pålitliga skivbromsar.`}</li>
      <li parentName="ul"><strong parentName="li">{`Användning:`}</strong>{` Perfekt för den moderna pendlaren och helgutforskaren.`}</li>
    </ul>
    <h3 {...{
      "id": "merida-espeeder-serien"
    }}>{`Merida eSPEEDER Serien`}</h3>
    <p>{`Merida eSPEEDER är stadshybridcyklar utformade för snabb och effektiv stadskörning.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Motor och Batteri:`}</strong>{` MAHLE X35+ Hub Engine för tyst och diskret assistans med batterikapacitet på 504 Wh.`}</li>
      <li parentName="ul"><strong parentName="li">{`Räckvidd:`}</strong>{` Upp till 50 km, idealisk för stadsresor och längre pendlingsturer.`}</li>
      <li parentName="ul"><strong parentName="li">{`Design och Komfort:`}</strong>{` Lätt aluminiumram och kolfiberframgaffel garanterar smidig och kontrollerad körning, robusta hydrauliska skivbromsar för säkerhet.`}</li>
      <li parentName="ul"><strong parentName="li">{`Användning:`}</strong>{` Idealisk för stadsbor som söker en snabb och elegant transportlösning.`}</li>
    </ul>
    <h3 {...{
      "id": "köpguide-för-merida-elcyklar"
    }}>{`Köpguide för Merida Elcyklar`}</h3>
    <p>{`Att välja rätt Merida elcykel kan vara en utmaning, men här är några tips för att hjälpa dig:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Användningsområde:`}</strong>{` Tänk på var och hur du kommer använda cykeln mest. För terräng och enduro, välj eONE-SIXTY. För stad och lättare terräng, kan eBIG.TOUR eller Espresso vara bättre.`}</li>
      <li parentName="ol"><strong parentName="li">{`Batterikapacitet:`}</strong>{` Om du planerar långa äventyr, se till att välja en modell med hög batterikapacitet (600 Wh eller mer).`}</li>
      <li parentName="ol"><strong parentName="li">{`Design och Komfort:`}</strong>{` Tänk på hur ramen och dämpningen kommer att påverka din komfort, särskilt om du planerar längre turer eller pendling.`}</li>
      <li parentName="ol"><strong parentName="li">{`Specifikationer:`}</strong>{` Uppmärksamma komponenter som motorstyrka, växelsystem och bromsar för att säkerställa att cykeln uppfyller dina behov och förväntningar.`}</li>
    </ol>
    <p>{`Oavsett vilka attribut du letar efter i en elcykel, har Merida en modell som passar dig. Utforska utbudet och hitta din nya Merida elcykel idag!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      